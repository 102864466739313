@font-face {
  font-family: "ClashGrotesk-Semibold";
  src: url("../fonts/ClashGrotesk-Semibold.woff2") format("woff2"),
    url("../fonts/ClashGrotesk-Semibold.woff") format("woff"),
    url("../fonts/ClashGrotesk-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100svh;
  overflow: hidden;
  font-family: "ClashGrotesk-Semibold";
}

.pagewrapper {
  position: fixed;
  width: 100%;
  max-width: 420px;
  height: 100svh;
  background: #090d0d;
}

.messagedefilant {
  display: block;
  margin: 40px auto;
  padding: 0;
  overflow: hidden;
  position: absolute;
  top: 60px;
  width: 119%;
  max-width: 640px;
  height: 50px;
  transform: rotate(-13deg);
  animation: slide 0.7s ease-out 0.5s backwards;
}
.messagedefilant div {
  position: absolute;
  top: 0;
  min-width: 100%;
}
.messagedefilant div span,
.messagedefilant div:after {
  position: relative;
  display: inline-block;
  font-size: 150%;
  color: #fff;
  background: #090d0d;
  border: 10px solid #fff;
  border-right: 0px;
  border-left: 0px;
  white-space: nowrap;
  top: 0;
}
.messagedefilant div span {
  animation: defilement 10s infinite linear;
}
.messagedefilant div:after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  animation: defilement2 10s infinite linear;
}
.messagedefilant2 {
  display: block;
  margin: 40px auto;
  padding: 0;
  overflow: hidden;
  position: absolute;
  top: 350px;
  width: 119%;
  max-width: 640px;
  height: 50px;
  transform: rotate(10deg);
  animation: slide2 0.7s ease-out 0.5s backwards;
}
.messagedefilant2 div {
  position: absolute;
  top: 0;
  min-width: 100%;
}
.messagedefilant2 div span,
.messagedefilant2 div:after {
  position: relative;
  display: inline-block;
  font-size: 150%;
  color: #fff;
  background: #090d0d;
  border: 10px solid #fff;
  border-right: 0px;
  border-left: 0px;
  white-space: nowrap;
  top: 0;
}
.messagedefilant2 div span {
  animation: defilement3 6s infinite linear;
}
.messagedefilant2 div:after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  animation: defilement4 6s infinite linear;
}

@media (min-width: 1024px) {
  .messagedefilant {
    top: 30px;
  }
  .messagedefilant2 {
    top: 245px;
  }
  .pagewrapper {
    height: 98svh;
  }
}

.triangle {
  position: relative;
  top: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 6.9s linear infinite;
}
.triangle_outer {
  position: absolute;
  width: 500px;
  height: 800px;
}
.triangle_outer::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 100%;
  background: #bff90b;
  animation: triangleslide 2500ms linear infinite;
}
.triangle_inner {
  width: 480px;
  height: 800px;
  background: #090d0d;
  transform: translateY(2px);
}
.triangle_inner::before {
  position: absolute;
  width: 20%;
  height: 100%;
  background: #ccc;
  animation: triangleslide 2500ms linear infinite;
}
.triangle .clip {
  clip-path: polygon(0 100%, 100% 100%, 50% 0);
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: all 0.5s linear;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: all 0.5s linear;
}

input[type="checkbox"] {
  position: relative;
  width: 60px;
  height: 30px;
  appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(255, 0, 0, 0.2);
  transition: 0.2s;
}
input:checked[type="checkbox"] {
  background: #bff90b;
}
input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}
input:checked[type="checkbox"]:before {
  left: 40px;
}

@keyframes opacityAnim {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    left: -600px;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    left: -35px;
  }
}
@keyframes defilement {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -100%;
  }
}
@keyframes defilement2 {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: 0%;
  }
}
@keyframes slide2 {
  0% {
    opacity: 0;
    right: -400px;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    right: -35px;
  }
}
@keyframes defilement3 {
  0% {
    margin-left: -100%;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes defilement4 {
  0% {
    margin-left: 0%;
  }
  100% {
    margin-left: 100%;
  }
}

@keyframes triangleslide {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(600px);
  }
}
@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
